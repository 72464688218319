<template>
  <div class="home">
    <BigHeader />

    <div class="container">
      <span class="is-size-3">Das Neuste von der Näharena</span>
      <Post />
    </div>
  </div>
</template>

<script>

import BigHeader from "@/components/BigHeader";
import Post from "@/components/Post";

export default {
  name: 'News',
  components: {
    Post,
    BigHeader
  }
}
</script>

<style scoped>
  .container {
    margin-top: 30px;
  }

  @media all and (min-width: 1024px) {
    .container {
      margin-top: 60px;
    }
  }

</style>
