<template>
  <div class="home">
    <BigHeader />
    <ContactForm />
  </div>
</template>

<script>

import BigHeader from "@/components/BigHeader";
import ContactForm from "@/components/ContactForm";

export default {
  name: 'Contact',
  components: {
    ContactForm,
    BigHeader

  }
}
</script>
