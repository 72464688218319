















import {Component, Vue, Prop} from 'vue-property-decorator';
@Component
export default class FormField extends Vue {
  @Prop() readonly name!: string
  @Prop() readonly label!: string
  @Prop() readonly type!: string
}
