









































import {Component, Vue} from 'vue-property-decorator';
import CourseCalendarMenu from "@/components/CourseCalendar/CourseCalendarMenu.vue";
import NaehwochenendeEvents from "@/components/CourseCalendar/content/NaehwochenendeEvents.vue";

@Component({
  components: {CourseCalendarMenu}
})
export default class CourseCalendar extends Vue {

  activePage = NaehwochenendeEvents

  setActivePage(newActivePage: any) {
    this.activePage = newActivePage
  }

}
