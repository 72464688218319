<template>
  <div class="home">
    <BigHeader />
    <Intro />
  </div>
</template>

<script>

import BigHeader from "@/components/BigHeader";
import Intro from "@/components/Intro";
import QuickLinks from "@/components/QuickLinks";
export default {
  name: 'Home',
  components: {
    QuickLinks,
    Intro,
    BigHeader

  }
}
</script>
