<template>
  <div class="home">
    <BigHeader />
    <CourseCalendar />
  </div>
</template>

<script>

import BigHeader from "@/components/BigHeader";
import CourseCalendar from "@/components/CourseCalendar/CourseCalendar";

export default {
  name: 'Courses',
  components: {
    CourseCalendar,
    BigHeader

  }
}
</script>
