











































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: { }
})
export default class NaehwochenendeEvents extends Vue {

}
