






























import {Component, Vue} from 'vue-property-decorator';
import FormField from "@/components/FormField.vue";
import SelectField from "@/components/SelectField.vue";
import Swal from "sweetalert2";

@Component({
  components: {SelectField, FormField}
})
export default class ContactForm extends Vue {

  mounted() {

    const mailSent = this.$route.query.mail_sent

    if (mailSent) {
      Swal.fire({
        title: 'Danke!',
        text: 'Danke für deine Anfrage, wir melden uns bei dir!',
        icon: 'success',
        confirmButtonText: 'Okay!'
      })
    }

    if (mailSent === 'false') {
      Swal.fire({
        title: 'Ups!',
        text: 'Sorry, hier ist ein Problem aufgetreten. Versuche es bitte noch einmal!',
        icon: 'error',
        confirmButtonText: 'Okay!'
      })
    }
  }

}
