




















































import {Component, Prop, Vue} from 'vue-property-decorator';
@Component
export default class WhatsAppOrderModal extends Vue {

  @Prop() readonly isOpen!: boolean
  @Prop() readonly toggleWhatsAppOrderModal!: () => {}

}
