













































import {Component, Prop, Vue} from 'vue-property-decorator';
import NaehwochenendeEvents from "@/components/CourseCalendar/content/NaehwochenendeEvents.vue";
import FerienNaehEventFuerTeens from "@/components/CourseCalendar/content/FerienNaehEventFuerTeens.vue";
import FreundinnenNaehEvent from "@/components/CourseCalendar/content/FreundinnenNaehEvent.vue";
import KreativTechnikTraining from "@/components/CourseCalendar/content/KreativTechnikTraining.vue";
import DatesFuerMaschinen from "@/components/CourseCalendar/content/DatesFuerMaschinen.vue";
import MaschinenBasisTraining from "@/components/CourseCalendar/content/MaschinenBasisTraining.vue";
import IntesivTrainingFuerMaschinen from "@/components/CourseCalendar/content/IntesivTrainingFuerMaschinen.vue";
import EinsteigerNaehkurs from "@/components/CourseCalendar/content/EinsteigerNaehkurs.vue";
import TaschenWorkshop from "@/components/CourseCalendar/content/TaschenWorkshop.vue";
import SchultuetenWorkshop from "@/components/CourseCalendar/content/SchultuetenWorkshop.vue";
import DirndlWorkshop from "@/components/CourseCalendar/content/DirndlWorkshop.vue";
import EasyFashionNaehkurs from "@/components/CourseCalendar/content/EasyFashionNaehkurs.vue";
import NaehDeinStyleNaehkurs from "@/components/CourseCalendar/content/NaehDeinStyleNaehkurs.vue";
import KidsNaehkurs from "@/components/CourseCalendar/content/KidsNaehkurs.vue";
import YoungFashionNaehkurs from "@/components/CourseCalendar/content/YoungFashionNaehkurs.vue";
import PersonalTraining from "@/components/CourseCalendar/content/PersonalTraining.vue";

@Component
export default class CourseCalendarMenu extends Vue {

  @Prop() readonly setActivePage!: (newActivePage: any) => {}

  NaehwochenendeEvents = NaehwochenendeEvents
  FerienNaehEventFuerTeens = FerienNaehEventFuerTeens
  FreundinnenNaehEvent = FreundinnenNaehEvent
  KreativTechnikTraining = KreativTechnikTraining
  DatesFuerMaschinen = DatesFuerMaschinen
  MaschinenBasisTraining = MaschinenBasisTraining
  IntesivTrainingFuerMaschinen = IntesivTrainingFuerMaschinen
  EinsteigerNaehkurs = EinsteigerNaehkurs
  TaschenWorkshop = TaschenWorkshop
  SchultuetenWorkshop = SchultuetenWorkshop
  DirndlWorkshop = DirndlWorkshop
  EasyFashionNaehkurs = EasyFashionNaehkurs
  NaehDeinStyleNaehkurs = NaehDeinStyleNaehkurs
  KidsNaehkurs = KidsNaehkurs
  YoungFashionNaehkurs = YoungFashionNaehkurs
  PersonalTraining = PersonalTraining

}
