<template>
  <div class="home">
    <BigHeader />
    <div class="container">
      <p class="is-size-1">Allgemeine Geschäftsbedingungen</p>
      <p>
        Allgemeine Geschäftsbedingungen für Nähkurse der Näharena Bühl!<br/><br/>
        <b>Anmeldung</b>: Die Teilnahme an unseren Nähkursen bedarf grundsätzlich einer schriftlichen Anmeldung bei gleichzeitiger Entrichtung der vollen Kursgebühren.<br/>
        Im Falle telefonischer Anmeldung kommt ein wirksamer Vertrag erst dann zustande, wenn die Kursgebühren innerhalb einer Woche entrichtet werden. Als Nachweis für die Entrichtung der Kursgebühren erhält jeder Teilnehmer/jede Teilnehmerin eine Quittung.<br/><br/>

        <b>Gebühren</b>: Die Kursgebühren sind auf dem Informationsformular ausgewiesen. Sie sind im Voraus zu entrichten. Die anfallenden Materialkosten sind, wenn keine deutlich andere Aussage besteht, nicht in den Gebühren enthalten.<br/><br/>

        <b>Rücktritt</b>: Der gebührenfreie Rücktritt ist nur durch schriftliche Rücktrittserklärung und Vorlage der für die Entrichtung der Kursgebühren übergebenen Quittung bis spätestens 30 Werktage vor dem ersten Veranstaltungstermin möglich. Bei einem Rücktritt innerhalb von 29-22 Tagen vor Beginn der Veranstaltung werden 75% der Kosten zurückerstattet. Innerhalb von 21-14 Tagen vor Beginn erstatten wir 50% der Gebühren zurück. Bei einem Rücktritt innerhalb von 13-6 Tagen vor Beginn der Veranstaltung erstatten wir 25% der Gebühren zurück. Bei einem Rücktritt ab 5 Tagen vor der Veranstaltung ist keine Rückzahlung der Gebühren möglich.<br/><br/>

        <b>Rücktritt durch die Näharena</b>: Die Näharena kann wegen mangelnder Beteiligung oder bei Ausfall der Kursleitung die Veranstaltung absagen. In diesem Fall werden bereits entrichtete Kursgebühren voll zurückerstattet. Werden durch Unpässlichkeit der Kursleitung Termine verschoben und diese zu einem anderen Zeitpunkt durchgeführt, besteht kein Anspruch auf Erstattung der Kursgebühren. Weitergehende Ansprüche gegen die Näharena sind ausgeschlossen.<br/><br/>

        <b>Teilnehmerinformation</b>: Die Näharena kann in den eigenen Räumen sowie für An- und Abfahrtswege keinerlei Haftung übernehmen. Wir klären zu Beginn jedes Nähkurses darüber auf, dass die Nähmaschine ein Handwerkszeug ist, weshalb Unkonzentriertheit oder Fehlbedienungen zu Verletzungen führen können!Auch hierfür übernimt die Näharena keine Haftung.<br/><br/>

        <b>Teilnehmerbescheinigung</b>: Alle Teilnehmer, die einen Kurs regelmäßig besucht haben, können auf Wunsch eine Teilnehmerbescheinigung erhalten.<br/><br/>

      </p>
    </div>
  </div>
</template>

<script>

import BigHeader from "@/components/BigHeader";

export default {
  name: 'Agb',
  components: {
    BigHeader

  }
}
</script>
