import { render, staticRenderFns } from "./CourseCalendar.vue?vue&type=template&id=cc5d4314&scoped=true&"
import script from "./CourseCalendar.vue?vue&type=script&lang=ts&"
export * from "./CourseCalendar.vue?vue&type=script&lang=ts&"
import style0 from "./CourseCalendar.vue?vue&type=style&index=0&id=cc5d4314&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cc5d4314",
  null
  
)

export default component.exports